<template>
  <b10-base>
    <v-stepper
      v-if="formFieldsLoaded"
      vertical
      non-linear
      :value="currentStep"
      class="elevation-0"
    >
      <ValidationObserver
        v-for="step in steps"
        v-slot="slotObserverProps"
        :key="step.id"
      >
        <v-stepper-step
          :step="step.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ step.title }}
          <small>{{ step.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="step.id"
        >
          <template
            v-for="stepField in step.formFields"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :key="stepField.name"
              :name="stepField.name"
              :rules="formRules[stepField.name]"
            >
              <v-text-field
                v-if="stepField.tipo === TDATO_TIPO_DATO.tipos.texto"
                v-model="form[stepField.name]"
                clearable
                flat
                :label="stepField.label"
                :error-messages="errors"
                :messages="stepField.messages"
              />
              <v-text-field
                v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.entero || stepField.tipo === TDATO_TIPO_DATO.tipos.decimal"
                v-model="form[stepField.name]"
                type="number"
                clearable
                flat
                :label="stepField.label"
                :error-messages="errors"
                :messages="stepField.messages"
              />
              <b10-date-picker
                v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.fecha"
                v-model="form[stepField.name]"
                :title="stepField.label"
                :error-messages="errors"
                :messages="stepField.messages"
              />
              <b10-time-picker
                v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.hora"
                v-model="form[stepField.name]"
                :title="stepField.label"
                :error-messages="errors"
                :messages="stepField.messages"
              />
              <b10-date-time-picker
                v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.fechaHora"
                v-model="form[stepField.name]"
                :title="stepField.label"
                :error-messages="errors"
                :messages="stepField.messages"
              />
              <b10-autocomplete
                v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.listaValores"
                v-model="form[stepField.name]"
                :items="stepField.valores"
                item-value="descripcion"
                item-text="descripcion"
                :label="stepField.label"
                clearable
                :error-messages="errors"
                :messages="stepField.messages"
              />
            </ValidationProvider>
          </template>
        </v-stepper-content>
      </ValidationObserver>
    </v-stepper>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './DatoFormData'
import _ from '@/utils/lodash'
import { TDATO_TIPO_DATO } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    idtabla: {
      type: [Number, String],
      default: null,
    },
    validarRequeridos: {
      type: [Number, String],
      default: 0,
    },
  },
  data () {
    return {
      steps: [],
      currentStep: 1,
      TDATO_TIPO_DATO,
      form: {},
      formRules: {},
      formFieldsLoaded: false,
    }
  },
  async created () {
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id, this.idtabla)
      let stepId = 1
      let lastStep = {
        idtgrupoTdato: -1,
        id: 1,
      }
      // tipos de datos con valores
      const inTdatoConValores = []
      for (const dato of formData) {
        if (
          dato.tdato_idtipo_tdato === TDATO_TIPO_DATO.tipos.listaValores &&
          !inTdatoConValores.includes(dato.idtdato)
        ) {
          inTdatoConValores.push(dato.idtdato)
        }
      }
      const tdatoValores = await Data.selectTdatoValor(this, inTdatoConValores)
      // crear pasos del asistente
      for (const dato of formData) {
        if (dato.idtgrupo_tdato !== lastStep.idtgrupoTdato) {
          let descGrupo = ''
          if (dato.tgrupo_tdato_descripcion) {
            descGrupo = dato.tgrupo_tdato_descripcion
          } else {
            descGrupo = 'Sin agrupación'
          }
          lastStep = {
            id: stepId,
            idtgrupoTdato: dato.idtgrupo_tdato,
            title: descGrupo,
            subtitle: 'Datos personalizados de presupuesto',
            formFields: [],
          }
          this.steps.push(lastStep)
          stepId += 1
        }
        const fieldname = null
        // agregar form field
        if (TDATO_TIPO_DATO.tiposNumber.includes(dato.tdato_idtipo_tdato)) {
          fieldname = `valor_numerico_${dato.iddato}`
          this.$set(this.form, fieldname, dato.valor_numerico)
        } else if (TDATO_TIPO_DATO.tiposDate.includes(dato.tdato_idtipo_tdato)) {
          fieldname = `valor_fecha_${dato.iddato}`
          this.$set(this.form, fieldname, dato.valor_fecha)
        } else {
          fieldname = `valor_${dato.iddato}`
          this.$set(this.form, fieldname, dato.valor)
        }
        // agregar form rule para requerido
        if (this.validarRequeridos.toString() === '1') {
          if (dato.tdato_requerido) {
            this.$set(this.formRules, fieldname, { required: true })
          }
        } else {
          // form rule vacía para crear siempre el ValidationProvider
          this.$set(this.formRules, fieldname, {})
        }
        // agregar control
        const stepField = {
          name: fieldname,
          label: dato.tdato_descripcion,
          tipo: dato.tdato_idtipo_tdato,
          messages: dato.tdato_requerido ? ['Campo requerido'] : null,
          valores: _.filter(tdatoValores, { idtdato: dato.idtdato }),
        }
        lastStep.formFields.push(stepField)
      }
      this.formFieldsLoaded = true
    },
  },
}
</script>

<style>
.v-stepper__label {
  text-shadow: none !important;
}
</style>
