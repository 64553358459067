import { APIFilter } from '@/utils/api'

export default {
  async selectFormData (Vue, id, idtabla) {
    const apiFilterDato = new APIFilter()
      .addExact('id', id)
      .addExact('idtabla', idtabla)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('dato.select', { filter: apiFilterDato })
    return resp.data.result.dataset
  },
  async selectTdatoValor (Vue, inTdato) {
    const apiFilterTDatoValores = new APIFilter()
      .addIn('idtdato', inTdato)
      .addGT('estado', 0)
    const resp = await Vue.$api.call(
      'tdatoValor.select',
      {
        filter: apiFilterTDatoValores,
        page_size: 0,
      }
    )
    return resp.data.result.dataset
  },
}
